<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="rounded-xl overflow-hidden" :disabled="loading">
    <v-toolbar class="primary" tag="div" flat dark tile>
      <div class="text-h6">{{ $t("webhooks") }}</div></v-toolbar
    >
    <template>
      <v-data-table
        :headers="headers"
        :items="webhooks"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ prettyDateShow(item.createdAt) }}
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
export default {
  mixins: [utilMixin],
  data() {
    return {
      options: {},
      total: 0,
      loading: false,
      headers: [
        {
          text: "Event",
          align: "start",
          sortable: false,
          value: "event",
        },
        { text: "Date", align: "start", sortable: false, value: "createdAt" },
        { text: "Details", align: "start", sortable: false, value: "calories" },
      ],
      webhooks: [],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getWebHooks();
      }
    },
  },
  methods: {
    getWebHooks() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      const body = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.loading = true;
      getAPI(`/zoom/list?limit=${body.limit}&offset=${body.offset}`)
        .then((res) => {
          this.webhooks = res.data.result;
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
};
</script>
<style lang=""></style>
